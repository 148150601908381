import { _updateApi } from 'redux/actions/api'

export function updateMembership(data, callback = (f) => f, error = (f) => f) {
  _updateApi(`/societies/membership-status`, data, callback, error)
}

export function updatepassword(data, callback = (f) => f, error = (f) => f) {
  _updateApi(`/users/adminresetpassword`, data, callback, error);
}

export function updateUser(data, callback = (f) => f, error = (f) => f) {
  _updateApi(`/users/update`, data, callback, error);
}


export function membershipApiUpdateRole(data, callback = (f) => f, error = (f) => f) {
  _updateApi(`/societies/membership-api-update-role`, data, callback, error)
}