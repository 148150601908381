import { CustomTable } from "components/UI";
import { CustomButton } from "components/UI";
import DaterangeSelector from "components/UI/DaterangeSelector";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { formatNumber } from "utils/helpers";
import { today } from "utils/helpers";
import { EmptyList } from "./ReportDetails";
import { expensesApi } from "../inventory/item-request/reqHelper";
import { getRoleLink } from "views/helper";

export default function ExpensesIndex() {
  const goto = useHistory();
  const { society, user } = useSelector((state) => state.auth);
  const _from = moment(today).subtract(6, "day").format("YYYY-MM-DD");

  const [data, setData] = useState([]);
  const [form, setForm] = useState({
    from: _from,
    to: today,
  });

  const handleRange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const tableFields = [
    {
      title: "S/N",
      custom: true,
      component: (item, i) => <div>{i + 1}</div>,
    },
    {
      title: "Enter by",
      value: "enter_by",
    },
    { title: "Description", value: "description" },
    {
      title: "Amount",
      custom: true,
      component: (item, i) => (
        <div className="text-right">{formatNumber(item.amount)}</div>
      ),
    },
    {
      title: "Submitted at",
      custom: true,
      component: (item, i) => (
        <div>{moment(item.created_at).format("DD-MM-YYY hh:mm")}</div>
      ),
      value: "created_at",
    },
  ];

  useEffect(() => {
    expensesApi(form, "select", society.id, (e) => setData(e.data));
  }, [form]);
  const total = data.reduce((it, id) => it + parseFloat(id.amount), 0);
  return (
    <div className="card card-body">
      <div className="row">
        <div className="col">
          <DaterangeSelector
            from={form.from}
            to={form.to}
            handleChange={handleRange}
          />
        </div>
      </div>

      <h4 className="text-right">
        Total: <b>{formatNumber(total)}</b>
      </h4>
      <CustomTable fields={tableFields} data={data} bordered />
      {data.length ? null : (
        <EmptyList message="Report is empty, check back later or change the date range." />
      )}
    </div>
  );
}
