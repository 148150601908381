import { CustomTable } from "components/UI";
import Loading from "components/UI/Loading";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSummaryTransaction } from "./api";
import { formatNumber } from "utils/helpers";
import { SearchBar } from "components/UI";
import { Card } from "reactstrap";
import DaterangeSelector from "components/UI/DaterangeSelector";

export default function QuickViewTrans() {
  const {
    auth: { society },
  } = useSelector((s) => s);

  const [data, setData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);

  const getHistory = useCallback(() => {
    setLoading(true);
    getSummaryTransaction(
      {
        society_id: society.id,
        query_type: "state",
      },
      (d) => {
        setData(d.data);
        setLoading(false);
      }
    );
  }, [society.id]);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  const tableFields = [
    { title: "State", value: "state" },
    {
      title: "Contribution",
      custom: true,
      component: (i) => (
        <div className="text-right">{formatNumber(i.contribution_balance)}</div>
      ),
    },
    {
      title: "Special Saving",
      custom: true,
      component: (i) => (
        <div className="text-center">
          {formatNumber(i.special_saving_balance)}
        </div>
      ),
    },
    {
      title: "Share bal.",
      custom: true,
      component: (i) => (
        <div className="text-center">{formatNumber(i.share_balance)}</div>
      ),
    },
    {
      title: "Loan bal.",
      custom: true,
      component: (i) => (
        <div className="text-center">{formatNumber(i.loan_balance)}</div>
      ),
    },
    {
      title: "Special Loan Bal.",
      custom: true,
      component: (i) => (
        <div className="text-center">
          {formatNumber(i.special_loan_balance)}
        </div>
      ),
    },
    {
      title: "Other Contributions Bal.",
      custom: true,
      component: (i) => (
        <div className="text-center">
          {formatNumber(i.other_contributions_balance)}
        </div>
      ),
    },
    {
      title: "Loan Eligibility",
      custom: true,
      component: (i) => (
        <div className="text-right">
          {formatNumber(i.contribution_balance * 2)}
        </div>
      ),
    },
  ];

  const rows = [];
  data &&
    data.forEach((item) => {
      if (item?.name?.toLowerCase().indexOf(filterText.toLowerCase()) === -1)
        return;

      rows.push(item);
      // }
    });
    console.log(data, rows);
  return (
    <div>
      <Card body>
        {loading && <Loading />}
        <SearchBar
          placeholder="Search State"
          filterText={filterText}
          onFilterTextChange={(e) => {
            setFilterText(e);
          }}
        />
        <DaterangeSelector />
        <CustomTable fields={tableFields} data={rows || []} />
      </Card>
    </div>
  );
}
