import { CustomButton } from "components/UI";
import { CustomTable } from "components/UI";
import { CustomForm } from "components/UI";
import BackButton from "components/UI/BackButton";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, CardTitle, FormGroup } from "reactstrap";
import { getBankListFW } from "redux/actions/account";
import { _postApi } from "redux/actions/api";
import { apiURL } from "variables";
import toast, { Toaster } from "react-hot-toast";
import { CURRENCY } from "variables";
import { formatNumber } from "components/UI/helpers";
import { getRoleLink } from "views/helper";
export default function NewAccountForm() {
  const defaultForm = {
    subhead:1,
    head:"",
    description: "",
    amount: "",
    account_status: "active",
    state:"",
    type: "",
    maximum_amount: "",
    bank_name: "",
    bank_code: "",
    account_no: "",
    account_name: "",
  };
  const [form, setForm] = useState(defaultForm);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const society = useSelector((state) => state.auth.society);

  const history = useHistory();
  console.log(form)

  const tableFields = [
    // { title: "Head", value: "head" },
    { title: "Type", value: "type" },
    { title: "Description", value: "description" },
    { title: "Account No", value: "account_no" },
    { title: "Account Name", value: "account_name" },
    {
      title: "Amount " + CURRENCY,
      custom: true,
      component: (item) => (
        <div className="text-right">
          {formatNumber(
            item.amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      ),
      className: "text-right",
    },
  ];

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const handleAdd = () => {
    console.log(
      (form.type === "State Dues" || form.type === "Others") &&
        form.state !== "" &&
        form.description &&
        form.description !== "" &&
        form.amount &&
        form.amount !== ""
    );
    if (
      // form.type &&
      (form.type === "State Dues" || form.type === "Others") &&
      form.state &&
      form.state !== "" &&
      form.description &&
      form.description !== "" &&
      form.amount &&
      form.amount !== ""
    ) {
      setList((p) => [...p, form]);
      setForm(defaultForm);
    }else if (
      form.type === "National Dues" &&
      form.description &&
      form.description !== "" &&
      form.amount &&
      form.amount !== ""
    ) {
            setList((p) => [...p, form]);
            setForm(defaultForm);
    } else {
      // alert('Please complete the form')
      toast.error("Please complete the form");
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    let finalList = list.map((a) => ({ ...a, user_phone: user.phone }));
    _postApi(
      `/accounts/new`,
      {
        created_by: user.id,
        user_phone: user.phone,
        society_id: society.id,
        data: finalList,
      },
      (d) => {
        setLoading(false);
        toast.success(d.message);
        history.push(getRoleLink("/setup-account"));
      },
      (e) => {
        setLoading(false);
        toast.error(e.message);
      }
    );
  };
  return (
    <>
      <BackButton />
      <Card body>
        <CardTitle className="h4 text-center mb-4 mt-0">
          Setup new account
        </CardTitle>
        <AccountFormComponent
          form={form}
          setForm={setForm}
          handleChange={handleChange}
        />

        <center className="mb-4">
          <CustomButton onClick={handleAdd}>Add to list</CustomButton>
        </center>

        {list.length ? (
          <>
            <CustomTable fields={tableFields} data={list} />

            <CustomButton loading={loading} onClick={handleSubmit}>
              Submit
            </CustomButton>
          </>
        ) : null}
        <Toaster position="top-center" />
      </Card>
    </>
  );
}

export const AccountFormComponent = ({
  form = {},
  setForm = (f) => f,
  handleChange = (f) => f,
}) => {
  const [banks, setBanks] = useState([]);
  const states = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "FCT - Abuja",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
  ];
  useEffect(() => {
    getBankListFW((l) => {setBanks(l.data);});
  }, []);


  const formFields = [
    {
      label: "Type",
      type: "custom",
      component: () => (
        <FormGroup>
          <label className="font-weight-bold">Type*</label>
          <select
            className={`form-control`}
            onChange={({ target: { value } }) =>{
            let type = value.split(',')[0];
            let head = value.split(',')[1];
              setForm((p) => ({ ...p, type: type, subhead: head}))
            }}
            // value={form.type}
            name="type"
          >
            <option>--select---</option>
            {[
              { type: "National Dues", code: 1001 },
              {type:"State Dues", code: 1002 },
              {type:"Others", code: 1003 },
            ].map((item, index) => (
              <option key={index} value={[item.type, item.code]}>
                {item.type}
              </option>
            ))}
          </select>
        </FormGroup>
      ),
    },
    // {
    //   label: "Head",
    //   value: form.head,
    //   name: "head",
    //   required: true,
    // },
    {
      type: "custom",
      col: 4,
      component: (item) => (
        <FormGroup>
          <label className="font-weight-bold">State* </label>
          <select
            className={`form-control`}
            onChange={({ target: { value} }) =>{
              let state = value.split(',')[0]
              let code = value.split(',')[1]
              console.log(parseInt(code))
              setForm((p) => ({ ...p, state: state, head: `1002${parseInt(code)+1}` }))
            }
            }
          >
            <option>--select---</option>
            {states.map((item, index) => (
              <option key={index} value={[item,index]}>
                {item}
              </option>
            ))}
          </select>
        </FormGroup>
      ),
    },
    {
      label: "Description",
      value: form.description,
      name: "description",
      required: true,
    },
    {
      label: "Amount",
      value: form.amount,
      required: true,
      name: "amount",
      type: "number",
    },
    // {
    //   type: "custom",
    //   col: "4",
    //   component: () => (
    //     <FormGroup>
    //       <label className="font-weight-bold">Miximum amount type</label>
    //       <select
    //         className={`form-control`}
    //         onChange={({ target: { value } }) =>
    //           setForm((p) => ({ ...p, maximum_amount_type: value }))
    //         }
    //         value={form.maximum_amount_type}
    //       >
    //         <option>--select---</option>
    //         {["Fixed", "Percentage"].map((item, index) => (
    //           <option key={index} value={item}>
    //             {item}
    //           </option>
    //         ))}
    //       </select>
    //     </FormGroup>
    //   ),
    // },
    {
      label: "Account No.",
      value: form.account_no,
      name: "account_no",
      col: 4,
    },
    {
      label: "Account Name",
      value: form.account_name,
      name: "account_name",
      required: true,
    },
    {
      type: "custom",
      col: 4,
      component: (item) => (
        <FormGroup>
          <label className="font-weight-bold">Bank Name</label>
          <select
            className={`form-control`}
            onChange={({ target: { value } }) => {
              let code = value.split(",")[0];
              let name = value.split(",")[1];
              setForm((p) => ({ ...p, bank_code: code, bank_name: name }));
            }}
          >
            <option>--select---</option>
            {banks.map((item, index) => (
              <option key={index} value={[item.code, item.name]}>
                {item.name}
              </option>
            ))}
          </select>
        </FormGroup>
      ),
    },
  ];

  return <CustomForm fields={formFields} handleChange={handleChange} />;
};
