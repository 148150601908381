import { CustomTable } from "components/UI";
import BackButton from "components/UI/BackButton";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardTitle, Input, Label, Row, Table } from "reactstrap";
import moment from "moment";
import { formatNumber } from "components/UI/helpers";
import { CustomButton } from "components/UI";
import { CURRENCY } from "variables";
import ModalNotification from "components/UI/ModalNotification";
// import { newLoanRepayment } from './api'
import toast from "react-hot-toast";
import { useHistory } from "react-router";
import { getRoleLink } from "views/helper";
import types from "redux/actions/types";
import { _postApi } from "redux/actions/api";
import { apiURL } from "variables";
import { CustomForm } from "components/UI";
import { _fetchApi } from "redux/actions/api";
import { maxAmount } from "components/UI/helpers";
import { config } from "flutterwaveconfig";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { flutterwaveObj } from "components/UI/helpers";
import { AiFillDelete } from "react-icons/ai";
import { approvePendingPayments } from "./api";
import { _formatNumber } from "components/UI/helpers";
import MemberListInput from "../utilities/MemberListInput";

function PaymentConfirmationPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [reference, setReference] = useState("");
  const [req_id, setReqId] = useState("");
  const {
    auth: { society, user },
    account: { loanScheduleByLoan, paymentList, selectedPayment },
    society: { members },
  } = useSelector((s) => s);

  const [approvalConfirmation, setApprovalConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [imageSrc, setImageSrc] = useState("");
  const [form, setForm] = useState([]);
  const [onlinePaymentLoading, setOnlinePaymentLoading] = useState(false);
  const [selectMembers, setSelectMembers] = useState({});
  const [national, setNational] = useState([]);
  const [state, setState] = useState([]);
  const [others, setOthers] = useState([]);

  const handleCheckNational = (index, head) => {
    let newArr = national.map((item, idx) =>
      item.head === head ? { ...item, checked: !item.checked } : item
    );

    setNational(newArr);
  };

  const handleCheckState = (index, head) => {
    let newArr = state.map((item, idx) =>
      item.head === head ? { ...item, checked: !item.checked } : item
    );

    setState(newArr);
  };
  const handleCheckOther = (index, head) => {
    let newArr = others.map((item, idx) =>
      item.head === head ? { ...item, checked: !item.checked } : item
    );

    setOthers(newArr);
  };
  // const handleChange = (event) => {
  //   const { name, checked, type } = event.target;

  //   setNational((prevState) =>
  //     prevState.map((item) =>
  //       item.description === name
  //         ? {
  //             ...item,
  //             checked: type === "checkbox" ? checked : item.checked, // Update checked state for checkbox
  //           }
  //         : item
  //     )
  //   );
  // };

  const getAccountList = useCallback(() => {
    _fetchApi(
      `/accounts/by-status/${society.id}?account_status=active`,
      (resp) => {
        if (resp && resp.success) {
          const _data = resp.data.map((i) => ({ ...i, checked: false }));
          // console.log(_data)
          dispatch({
            type: types.loan.SET_PAYMENT_LIST,
            payload: resp.data,
          });
          setForm(_data);
          setNational(_data.filter((item) => item.type === "National Dues"));
          setState(
            _data.filter(
              (item) =>
                item.type === "State Dues" && item.state === selectMembers.state
            )
          );
          console.log(
            _data.filter(
              (item) =>
                item.type === "State Dues" && item.state === selectMembers.state
            ),
            selectMembers
          );
          setOthers(
            _data.filter(
              (item) =>
                item.type === "Others" && item.state === selectMembers.state
            )
          );
        } else {
          toast.error(resp.message);
        }
      },
      (e) => {
        console.log(e);
        // alert(resp.message)
      }
    );
  }, [dispatch, selectMembers]);

  useEffect(() => getAccountList(), [getAccountList]);

  const payWithFlutterWave = (payload) => {
    setLoading(true);
    let total_amount = selectedPayment.reduce(
      (a, b) => a + parseFloat(b.amount),
      0
    );
    let formData = new FormData();
    formData.append("selected_contributions", JSON.stringify(selectedPayment));
    formData.append(
      "member_name",
      selectMembers.firstname + " " + selectMembers.lastname
    );
    formData.append("society_id", society.id);
    formData.append("status", "waiting");
    formData.append("member_id", selectMembers.user_id);
    formData.append("total_amount", total_amount);
    fetch(`${apiURL}/contribution/new-request`, {
      method: "POST",
      body: formData,
    })
      .then((raw) => raw.json())
      .then((resp) => {
        console.log(resp);
        if (resp.success) {
          setReference(resp.reference);
          setReqId(resp.req_id);
          payload(resp);
        } else {
          toast.error(resp.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        toast.error(e.message);
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    if (selectedPayment.length) {
      if (attachment) {
        let total_amount = selectedPayment.reduce(
          (a, b) => a + parseFloat(b.amount),
          0
        );
        let formData = new FormData();
        formData.append(
          "selected_contributions",
          JSON.stringify(selectedPayment)
        );

        formData.append(
          "member_name",
          selectMembers.firstname + " " + selectMembers.lastname
        );
        formData.append("society_id", society.id);
        formData.append("member_id", selectMembers.user_id);
        formData.append("total_amount", total_amount);
        if (attachment && attachment.length) {
          attachment.forEach((file) => formData.append("attachments", file));
        }

        fetch(`${apiURL}/contribution/new-request`, {
          method: "POST",
          body: formData,
        })
          .then((raw) => raw.json())
          .then((resp) => {
            if (resp.success) {
              setReference(resp.reference);
              setReqId(resp.req_id);
              toast.success(resp.message);
              history.push(getRoleLink("/dashboard"));
            } else {
              toast.error(resp.message);
              setLoading(false);
            }
            dispatch({ type: types.loan.CLEAR_SELECTED_PAYMENT_LIST });
          })
          .catch((e) => {
            toast.error(e.message);
            setLoading(false);
          });
      } else {
        _postApi(
          "/contribution/new",
          {
            member_name: selectMembers.firstname + " " + selectMembers.lastname,
            selected_contributions: selectedPayment,
            society_id: society.id,
            member_id: selectMembers.user_id,
          },
          (d) => {
            if (d.success) {
              setLoading(false);
              toast.success(d.message);
              history.push(getRoleLink("/dashboard"));
              dispatch({ type: types.loan.CLEAR_SELECTED_PAYMENT_LIST });
            } else {
              toast.error(d.message);
              setLoading(false);
            }
          },
          (e) => {
            toast.error(e.message);
            setLoading(false);
          }
        );
      }
    } else {
      toast.error("You have not made any selection.");
      setLoading(false);
    }
  };

  const tableFields = [
    {
      title: "Type",
      custom: true,
      component: (i) => <div>{i.type}</div>,
    },
    // {
    //   title: "Date",
    //   custom: true,
    //   component: (i) => <div>{moment(i.month_year).format("MM, YYYY")}</div>,
    // },
    {
      title: "Description",
      custom: true,
      component: (i) => <div>{i.description}</div>,
    },
    {
      title: `Amount (${CURRENCY})`,
      custom: true,
      component: (i) => (
        <div className="text-right">{_formatNumber(i.amount)}</div>
      ),
    },
    // {
    //   title: "Action",
    //   custom: true,
    //   component: (i, index) => (
    //     <div>
    //       <Button size="sm" onClick={() => handleDelete(index)} color="danger">
    //         <AiFillDelete />{" "}
    //       </Button>
    //     </div>
    //   ),
    // },
  ];
  const _data = [
    ...national.filter((it) => it.checked),
    ...state.filter((it) => it.checked),
    ...others.filter((it) => it.checked),
  ];
  const totalAmount = _data.reduce((a, b) => a + parseFloat(b.amount), 0);
  // const _fields = [
  //   {
  //     type: "custom",
  //     custom: true,
  //     component: () => (
  //       <>
  //         <Label>Description</Label>
  //         <Input
  //           type="select"
  //           label="Description"
  //           labelKey="description"
  //           col={12}
  //           name="description"
  //           value={form.head}
  //           onChange={handleChange}
  //         >
  //           <option>--Select--</option>
  //           {paymentList.map((item, _key) => (
  //             <option value={item.head}>{item.description}</option>
  //           ))}
  //         </Input>
  //       </>
  //     ),
  //   },
  //   {
  //     type: "number",
  //     name: "amount",
  //     label: "Amount",
  //     autocomplete: "false",
  //     disabled: form?.head === "" ? true : false,
  //     value: form.amount,
  //     handleChange: handleChange,
  //   },

  // ];

  const handleFlutterPayment = useFlutterwave(
    flutterwaveObj(
      society.public_key,
      totalAmount +
        ((totalAmount * 1.7) / 100 >= 2000 ? 2000 : (totalAmount * 1.7) / 100) +
        50,
      society.society_logo,
      selectMembers,
      form.description,
      {
        type: "Savings",
        id: selectMembers.user_id,
        society_id: society.id,
        selectedPayment: JSON.stringify(selectedPayment),
        reference,
        req_id,
        total_amount: selectedPayment.reduce(
          (a, b) => a + parseFloat(b.amount),
          0
        ),
      },
      society.sub_account_id,
      society.settlement_type
    )
  );

  const approvedPayment = (response, resp) => {
    console.log({ response, resp });
    // let arr = [];
    // selectedPayment &&
    //   selectedPayment.forEach((item) => {
    //     arr.push({
    //       ...item,
    //       req_id: resp.req_id,
    //       society_id: society.id,
    //       created_by: selectMembers.user_id,
    //       member_name: selectMembers`${.firstname} selectMembers${.lastname}`,
    //       account_head: item.head,
    //       debit: 0,
    //       credit: item.amount,
    //       reference: resp.reference,
    //     });
    //   });
    approvePendingPayments(
      resp.req_id,
      {
        ...response,
        reference,
        created_by: selectMembers.user_id,
        society_id: society.id,
        total_amount: totalAmount,
        req_id: resp.req_id,
        reference: resp.reference,
        approved_by: selectMembers.user_id,
        requestInfo: resp.resullt,
      },
      (d) => {
        console.log(response);
        console.log(resp);
        if (d.success) {
          if (response.status === "successful") {
            history.push(getRoleLink("/dashboard"));
            dispatch({
              type: types.loan.CLEAR_SELECTED_PAYMENT_LIST,
            });
            setLoading(false);
            closePaymentModal(); // this will close the modal programmatically
            toast.success("Transaction successfull");
          } else {
            toast.error("Transaction unsuccessfull");
          }
        }
      },
      (e) => {
        console.log(e);
        setLoading(false);
        if (e.success) {
          // toast.error(e.message);
          // history.push("/admin/pending-member-payments");
        } else {
          toast.error(e.message);
        }
      }
    );
  };

  useEffect(() => {
    if (!Object.keys(selectMembers).length) {
      setSelectMembers({ ...user, user_id: user.id });
    }
  }, [form.user_id, selectMembers]);

  return (
    <div>
      <Card body>
        <CardTitle className="h4 text-center mb-4 d-flex justify-content-between ">
          <div>Select any of the payments below!</div>

          <MemberListInput
            value={selectMembers.user_id}
            onChange={(e) => {
              let value = e.target.value;
              const selected = members.find((e) => e.user_id === value);
              {
                console.log(selected);
                console.log("selected member", selectMembers);
              }
              setForm((p) => ({ ...p, user_id: value }));
              setSelectMembers({ ...selected, id: selected.user_id });
            }}
          />
        </CardTitle>
        {/* {JSON.stringify(form)} */}
        <div className="row">
          <div className="col-md-4">
            <>
              <h5>NATIONAL PAYMENTS</h5>
              {national.map((payment, idx) => (
                <>
                  <div className="custom-control custom-checkbox mb-3 ">
                    <input
                      checked={payment.checked}
                      id={`${payment.head}${payment.amount}`}
                      type="checkbox"
                      className="custom-control-input form-check-input"
                      onChange={() => {
                        handleCheckNational(idx, payment.head);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`${payment.head}${payment.amount}`}
                    >
                      <span>
                        {payment.description} (₦{payment.amount})
                      </span>
                    </label>
                  </div>
                </>
              ))}
            </>
          </div>
          <div className="col-md-4">
            <>
              <h5>STATE PAYMENTS</h5>
              {state.map((payment, idx) => (
                <>
                  <div className="custom-control custom-checkbox mb-3 ">
                    <input
                      name={payment.description}
                      id={`${payment.head}${payment.amount}`}
                      type="checkbox"
                      className="custom-control-input form-check-input"
                      onChange={() => {
                        handleCheckState(idx, payment.head);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`${payment.head}${payment.amount}`}
                    >
                      <span>
                        {payment.description} (₦{payment.amount})
                      </span>
                    </label>
                  </div>
                </>
              ))}
            </>
          </div>
          <div className="col-md-4">
            {" "}
            <>
              <h5>OTHERS</h5>
              {others.map((payment, idx) => (
                <>
                  <div className="custom-control custom-checkbox mb-3 ">
                    <input
                      name={payment.description}
                      id={`${payment.head}${payment.amount}`}
                      type="checkbox"
                      className="custom-control-input form-check-input"
                      onChange={() => {
                        handleCheckOther(idx, payment.head);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`${payment.head}${payment.amount}`}
                    >
                      <span>
                        {payment.description} (₦{payment.amount})
                      </span>
                    </label>
                  </div>
                </>
              ))}
            </>
          </div>
        </div>

        <CustomTable
          fields={tableFields}
          data={_data}
          total={["", "Total", _formatNumber(totalAmount)]}
        />
        <center>
          <CustomButton
            onClick={() => {
              attachment
                ? setApprovalConfirmation(true)
                : setOnlinePaymentLoading(true);
            }}
            loading={loading}
            className="mb-3"
          >
            {attachment ? "Submit Payment Request" : "Pay Now"}
          </CustomButton>
        </center>
        {/* <div>
          {imageSrc && (
            <img src={imageSrc} alt="Selected Image" className="img-fluid" />
          )}
        </div> */}
      </Card>
      <ModalNotification
        isOpen={approvalConfirmation}
        confirm={handleSubmit}
        confirmLoading={loading}
        toggle={() => setApprovalConfirmation((p) => !p)}
      >
        <h3 className="text-center">
          Are you sure you want to make payment of a total sum of {CURRENCY}
          {_formatNumber(totalAmount)}
        </h3>
      </ModalNotification>

      <ModalNotification
        isOpen={onlinePaymentLoading}
        confirm={() =>
          payWithFlutterWave((resp) =>
            handleFlutterPayment({
              callback: (response) => {
                approvedPayment(response, resp);
              },
              onClose: () => {
                setLoading(false);
              },
            })
          )
        }
        headerText={<b>Payment Confirmation</b>}
        confirmText={
          <div>
            {" "}
            Pay Now (
            <b>
              {_formatNumber(
                ((parseFloat(totalAmount) * 1.7) / 100 >= 2000
                  ? 2000
                  : (parseFloat(totalAmount) * 1.7) / 100) +
                  parseFloat(totalAmount) +
                  50
              )}
            </b>{" "}
            )
          </div>
        }
        confirmLoading={loading}
        toggle={() => setOnlinePaymentLoading((p) => !p)}
      >
        {/* {JSON.stringify(selectedPayment)} */}
        <div className="text-center">
          <Table bordered size="sm" responsive>
            <thead>
              <th className="text-left">Description</th>
              <th>Amount({CURRENCY})</th>
            </thead>
            <tbody>
              <tr>
                <td className="text-left ">{"Savings"}</td>
                <td className="text-right">{_formatNumber(totalAmount)}</td>
              </tr>
              <tr>
                <td className="text-left ">Switch fee</td>
                <td className="text-right ">
                  {_formatNumber(
                    (parseFloat(totalAmount) * 1.7) / 100 >= 2000
                      ? 2000
                      : (parseFloat(totalAmount) * 1.7) / 100
                  )}
                </td>
              </tr>
              <tr>
                <td className="text-left">Stamp duty</td>
                <td className="text-right">{"50.00"}</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-right">
                  <b>
                    {_formatNumber(
                      ((parseFloat(totalAmount) * 1.7) / 100 >= 2000
                        ? 2000
                        : (parseFloat(totalAmount) * 1.7) / 100) +
                        parseFloat(totalAmount) +
                        50
                    )}
                  </b>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </ModalNotification>
    </div>
  );
}

// const PaymentSelectionForm = ({
//   fields = [],
//   handleChange = (f) => f,
//   addToList = (f) => f,
// }) => {
//   return (
//     <>
//       <CustomForm fields={fields} handleChange={handleChange} />

//     </>
//   );
// };

export default PaymentConfirmationPage;
