import Loading from "components/UI/Loading";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { getMembers } from "redux/actions/society";

export default function StateListInput(props) {
  const { members } = useSelector((o) => o.society);
  const { society } = useSelector((o) => o.auth);
  const { label = true } = props;
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);

 const states = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "FCT - Abuja",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
  ];
  return (
    <FormGroup style={{ marginTop: -10 }} className={props.container}>
      {fetching && <Loading size="sm" />}
      {label && <label>Select State</label>}
      <select
        className="form-control"
        type="select"
        onChange={props.onChange}
        value={props.value}
      >
        <option>---select---</option>
        {states.map((item) => (
          <option
            labelkey={item}
            key={item}
            value={item}
          >
            {item}
          </option>
        ))}
      </select>
    </FormGroup>
  );
}
