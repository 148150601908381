import { CustomButton } from "components/UI";
import React from "react";
import { Table } from "reactstrap";

const CandidateList = ({ candidates, handleDelete }) => {
  return (
    <Table className="mt-3" striped bordered>
      <thead>
        <tr>
          <th>Name</th>
          <th>Position</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {candidates.map((candidate, index) => (
          <tr key={index}>
            <td>{candidate.name}</td>
            <td>{candidate.position}</td>
            <td>
              <CustomButton color="danger" onClick={() => handleDelete(index)}>
                Delete
              </CustomButton>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CandidateList;
