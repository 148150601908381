import { CustomButton } from "components/UI";
import { CustomForm } from "components/UI";
import ToggleSwitch from "components/UI/switch/ToggleSwitch";
import React from "react";
import { useSelector } from "react-redux";
import { Card, CardTitle, Col, Row } from "reactstrap";
import { getImageUrl } from "redux/actions/api";
import LoanSettings from "./LoanSettings";
import BankDetails from "../../examples/BankDetails";
import LevelOfApproval from "./LevelOfApproval";
import GuarantorSetup from "./GuarantorSetup";
export default function Settings() {
  const society = useSelector((state) => state.auth.society);
  return (
    <div>
      {/* <h3 className="text-center">Settings</h3> */}
      <Row>
        <Col>
          <Card body>
            <CardTitle className="text-center h4 mb-2">
              Cooperative Profile Information
            </CardTitle>

            <div>
              <Row className="">
                <Card className="col-md-2">
                  <img
                    src={getImageUrl(society.society_logo)}
                    alt="society_logo"
                    className="img-fluid rounded"
                  />
                </Card>
                <div className="col-md-10 py-2">
                  <div>Name: {society.society_name}</div>
                  <div>Contact Phone: {society.society_name}</div>
                  <div>Contact Address: {society.society_address}</div>
                  <div>
                    Membership Link:{" "}
                    <a href={society.membership_url}>
                      {society.membership_url}
                    </a>
                  </div>
                </div>
              </Row>
            </div>
            {/* {JSON.stringify(society)} */}
          </Card>
        </Col>
        <Col md={6}>
          <Card body>
            <CardTitle className="text-center h4 mb-2">
              Contribution Settings
            </CardTitle>

            <CustomForm
              fields={[
                {
                  label: "Default Contribution Amount",
                  name: "default_contribution",
                  col: 12,
                },
              ]}
            />
            <center>
              <CustomButton size="sm">Save Changes</CustomButton>
            </center>
          </Card>
        </Col>
        {/* <Col md={6}>
          <LoanSettings />
        </Col> */}
        <Col md={6}>
          <BankDetails />
        </Col>
        {/* <Col md={6}>
          <LevelOfApproval title="Loan Approval Level" />
        </Col> */}
        <Col md={6}>
          <LevelOfApproval title="Ordinary Savings Approval Level" />
        </Col>
        <Col md={6}>
          <LevelOfApproval title="Withdrawal Approval Level" />
        </Col>
        {/* <Col md={6}>
          <GuarantorSetup title="Guarantor Setup" />
        </Col> */}
      </Row>
    </div>
  );
}
