import { _postApi } from "redux/actions/api";

const callApi = (
  type = "",
  data = {},
  cb = (f) => f,
  error = (f) => f,
  queryType = "insert",
  society_id
) => {
  _postApi(
    `/${type}?query_type=${queryType}&society_id=${society_id}`,
    data,
    (response) => {
      cb(response);
    },
    (err) => {
      error(err);
    }
  );
};

export function deleteNode(
  type = "",
  node = "",
  cb = (f) => f,
  error = (f) => f,
  society_id
) {
  callApi(type, { parent: node.title }, cb, error, "delete", society_id);
}

export function getNextCodeForHead(
  type = "",
  head = "",
  cb = (f) => f,
  error = (f) => f,
  society_id
) {
  callApi(type, { parent: head }, cb, error, "next_code", society_id);
}

export function saveNewChart(
  type = "",
  data = [],
  cb = (f) => f,
  error = (f) => f,
  society_id
) {
  callApi(type, data, cb, error, "insert", society_id);
}

export function getChartHead(
  type = "",
  cb = (f) => f,
  error = (f) => f,
  society_id
) {
  callApi(type, {}, cb, error, "heads", society_id);
}

export function getChart(
  type = "",
  queryType = "chart",
  cb = (f) => f,
  error = {},
  society_id
) {
  callApi(type, {}, cb, error, queryType, society_id);
}

export function updateChart(
  type = "",
  data = [],
  cb = (f) => f,
  error = (f) => f,
  society_id
) {
  callApi(type, data, cb, error, "update", society_id);
}
