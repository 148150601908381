// import { PDFViewer, Text, View } from "@react-pdf/renderer";
import React, { useCallback, useEffect, useState } from "react";
// import Certificate from "./Certificate";
// import { _get } from "../../../lib/Helper";
import BackButton from "components/UI/BackButton";
import "./certificate.css"
import useQuery from "../../../helpers/useQuery";
import { useParams } from "react-router";
import moment from "moment";
import { CustomButton } from "components/UI";
import { themeClass } from "variables";

export default function LicensViever() {
  const [data, setData] = useState([]);
 const query = useQuery()
  const name = query.get('name')
  const date= query.get('date')
 const param = useParams()

  console.log(name,date)

  // alert("Vehicle")

  return (
    <div>
      <div className="d-flex justify-content-between">
        <BackButton />
        <CustomButton
          size="sm"
          className={`m-2 `}
          color={themeClass.split("-")[1]}
          onClick={() => window.print()}
        >
          <span className="d-flex flex-direction-row align-items-center">
            Download
          </span>
        </CustomButton>
      </div>

      <div
        style={{ width: "100%" }}
        className="text-center d-flex flex-column container-fluid pdf justify-content-evenly"
      >
        <p className="cert-id">ID : {param.id}</p>
        <img
          className="logo"
          src="https://th.bing.com/th/id/OIP.XYLIiK0OkIBW1h__oTBrvgHaGU?rs=1&pid=ImgDetMain"
        />
        <div className="ass-name d-flex align-center justify-around">
          <img
            className="cert-logo"
            src="https://th.bing.com/th/id/OIP.XYLIiK0OkIBW1h__oTBrvgHaGU?rs=1&pid=ImgDetMain"
            alt="logo"
          />
          <h3>NIGERIAN ASSOCIATION OF SMALL & MEDIUM ENTERPRISES</h3>
        </div>
        <div className="middle">
          <h3>Certificate of Membership</h3>
          <h4>This is to certify that</h4>
          <div>
            <h2 className="mb-0">{name ? "Ahmad Ismail" : name}</h2>

            <hr className="name mt-1" />
          </div>

          <h5>
            is a registered member of
            <b className="red"> NASME</b>
          </h5>
          <div className="d-flex issue-date">
            <div className="issue ">
              <h4>Issued Date</h4>
              <div className="date">
                <span className="">{moment(date).calendar()}</span>
                <hr className="m-0 w-100" />
              </div>
            </div>
            <div className="issue ">
              <h4>Due Date</h4>
              <div className="date">
                <span className="">
                  {moment(date).add(335, "days").calendar()}
                </span>
                <hr className="m-0 w-100" />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-around align-item-center">
          <div className="sign">
            <hr />
            <h4>President/Chairman of Council</h4>
          </div>
          <div className="circle">
            <h3>MEMBER</h3>
          </div>
          <div className="sign">
            <hr />
            <h4>Executive Secretary/CEO</h4>
          </div>
        </div>
      </div>
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}> */}
      {/* <View> */}
      {/*    <Text>DJDJDJ</Text>*/}

      {/* </View> */}
      {/* <Certificate data={data[0]} /> */}
      {/* </PDFViewer> */}
    </div>
  );
}
