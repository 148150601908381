import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { unflatten } from "redux/actions/api";
import StructureForm from "./StructureForm";
import StructureTree from "./StrutureTree";
import {
  deleteNode,
  getChart,
  getChartHead,
  getNextCodeForHead,
  saveNewChart,
  updateChart,
} from "./api";
import { useLocation } from "react-router";
import { BorderedCard } from "components/UI";
import { SelectInput } from "components/UI";
import { _customNotify } from "utils/helpers";
import { useSelector } from "react-redux";

function Structure1({ type = "structure", prefix = true }) {
  const initForm = { parent: "", description: "", code: "", prefix: "" };
  const [form, setForm] = useState(initForm);
  const [heads, setHeads] = useState([]);
  const [treeData, setTreeData] = useState([{aaa:1233}]);
  const [submitting, setSubmitting] = useState(false);
  const headRef = useRef(null);
  const { society } = useSelector((state) => state.auth);

  const resetForm = () => setForm(initForm);
  const location = useLocation();
  const query_type = "chart";

  const _getNextCodeForHead = (head) => {
    getNextCodeForHead(
      type,
      head,
      (data) => {
        setSubmitting(false);
        console.log(data);
        if (data) {
          setForm((p) => ({ ...p, code: data.results[0].next_code }));
        }
      },
      (err) => {
        setSubmitting(false);
        console.log(err);
      },
      society.id
    );
  };

  const getStructure = useCallback(() => {
    getChart(
      type,
      query_type,
      (data) => {
        if (data) {
          let arrInTree = unflatten(data.results);
          console.log(data, "LSLSLSSLS", arrInTree);
          setTreeData(arrInTree);
          //   alert(JSON.stringify(arrInTree));
        }
      },
      (err) => {
        console.log(err);
      },
      society.id
    );
  }, [type, query_type]);

  const fields = [
    // {
    //   name: "parent",
    //   type: "custom",
    //   component: () => (
    //     <SelectInput
    //       label="Parent Head"
    //       options={heads.map((i) => `${i.head}`)}
    //       ref={headRef}
    //       labelKey={(item) => `${item.head} - ${item.description}`}
    //       onChange={({ target: { value } }) => {
    //         // console.log(value, "Adewa");
    //         let selected = heads.find((a) => a.head === value);
    //         setForm((p) => ({
    //           ...p,
    //           parent: selected.head,
    //         }));
    //         _getNextCodeForHead(selected.head);
    //       }}

    //       //   onChange={(item) => {
    //       //     if (item.length) {
    //       //       setForm((p) => ({ ...p, parent: item[0].head }));
    //       //       _getNextCodeForHead(item[0].head);
    //       //     }
    //       //   }}
    //     />
    //   ),
    //   value: form.parent,
    //   col: 12,
    // },
    {
      name: "description",
      label: "Description",
      value: form.description,
      col: 12,
    },
    { name: "code", label: "head", value: form.code, col: 12 },
    { name: "code", label: "Sub-head", value: form.code, col: 12 },
    {
      name: "amount",
      label: "Amount",
      value: form.amount,
      col: 12,
      type: "number",
    },
    // {
    //   name: "prefix",
    //   label: "Prefix",
    //   value: (form.prefix = form.description.substring(0, 3).toUpperCase()),
    //   col: 12,
    // },
  ];

  // if (prefix)
  //   fields.push({
  //     name: "prefix",
  //     label: "Prefix",
  //     value: form.prefix,
  //     col: 12,
  //   });

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const getHeads = useCallback(() => {
    getChartHead(
      type,
      (data) => {
        //   setSubmitting(false);
        // setHeads(data?.results);
        // console.log(data);
      },
      (err) => {
        //   setSubmitting(false);
        console.log(err);
      },
      society.id
    );
  }, [type]);

  useEffect(() => {
    getHeads();
  }, [getHeads]);

  useEffect(() => {
    getStructure();
  }, [getStructure]);

  const submitForm = (data) => {
    setSubmitting(true);
    saveNewChart(
      type,
      data,
      () => {
        setSubmitting(false);
        getStructure();
        resetForm();
        _customNotify("Created Successfully");
      },
      (err) => {
        setSubmitting(false);
        console.log(err);
      },
      society.id
    );
  };
  const handleUpdate = (data) => {
    setSubmitting(true);
    updateChart(
      type,
      data,
      () => {
        setSubmitting(false);
        getStructure();
        resetForm();
        _customNotify("Update Successfully");
      },
      (err) => {
        setSubmitting(false);
        console.log(err);
      },
      society.id
    );
  };

  const addChild = (node) => {
    setForm((p) => ({ ...p, parent: node.title }));
    headRef.current = {
      text: `${node.title} - ${node.description}`,
    };
    _getNextCodeForHead(node.title);
  };

  const editNode = (node) => {
    headRef.current = { text: node.subhead };
    setForm((p) => ({
      ...p,
      parent: node.subhead,
      description: node.description,
      code: node.title,
      update: true,
    }));
  };

  const _deleteNode = (node) => {
    deleteNode(
      type,
      node,
      () => {
        setSubmitting(false);
        // console.log(data);
        getStructure();
        getHeads();
      },
      () => {
        setSubmitting(false);
      },
      society.id
    );
  };

  return (
    <BorderedCard className="p-2">
      <Row>
        <Col md={5}>
          {/* {JSON.stringify(heads)} */}
          <StructureForm
            loading={submitting}
            handleSubmit={submitForm}
            fields={fields}
            handleChange={handleChange}
            form={form}
            handleUpdate={handleUpdate}
          />
        </Col>
        <Col md={7}>
          <StructureTree
            addChild={addChild}
            editNode={editNode}
            deleteNode={_deleteNode}
            treeData={treeData}
          />
        </Col>
      </Row>
    </BorderedCard>
  );
}

export default Structure1;
