import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "components/UI/CustomTable";
import { AiFillEye } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import {
  Badge,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { SearchBar, CustomButton } from "components/UI";
import { _fetchApi } from "redux/actions/api";
import { useDispatch, useSelector } from "react-redux";
import { getMembers } from "redux/actions/society";
import Loading from "components/UI/Loading";
import { formatNumber } from "components/UI/helpers";
import { Dropdown } from "react-bootstrap";
import { theme } from "variables";
import { CustomForm } from "components/UI";
import CustomModal from "components/UI/CustomModal";
import { TextInput } from "components/UI";
import { SelectInput } from "components/UI";
import { membershipApiUpdateRole, updateMembership,updatepassword,updateUser } from "./api";
import toast from "react-hot-toast";
import ContributionSchedule from "../monthlyContribution/ContributionSchedule";
import { _formatNumber } from "components/UI/helpers";
import { getRoleLink } from "views/helper";
import MemberImportExcel from "./MemberImportExcel";
import AdminListInput from "../utilities/AdminListInput";
import {FormGroup } from "reactstrap"
import { State } from "utils";

export default function MembershipApplication() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { society } = useSelector((o) => o.auth);
  const { members } = useSelector((o) => o.society);
  const [fetching, setFetching] = useState(false);
  const [defaultAccount, setDefaultAccount] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [changeText, setChangeText] = useState("");
  const [changeValue, setChangeValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [_fetch, setFetch] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectMembers, setSelectMembers] = useState({});
  const [form, setForm] = useState({})

  const toggle = () => setModal(!modal);

  const onInputChange = ({ target: { value, name } }) => {
    if (value !== "") {
      setDisabled(false);
    }
    setChangeValue((p) => ({ ...p, [name]: value, society_id: society.id }));
  };
  const cancelModal = () => {
    setIsOpen(false);
     setDisabled(true);
  };
  const getDefaultAccount = useCallback(() => {
    _fetchApi(
      `/accounts/by-status/${society.id}?query_type=default`,
      (resp) => {
        if (resp && resp.data && resp.data.length) {
          setDefaultAccount(resp.data[0]);
        } else {
          // toast.error(resp.message)
        }
      },
      (e) => {
        // toast.error(e.message)
      }
    );
  }, [society.id]);


  const fields = [
    { title: "Applicatio No", value: "membership_id" },
    { title: "Name", value: "name" },
    { title: "Phone", value: "phone" },
    { title: "Email Address", value: "email" },

    {
      title: "Contribution",
      custom: true,
      component: (i) => (
        <div>
          {i.default_contribution
            ? _formatNumber(i.default_contribution)
            : _formatNumber(defaultAccount.amount)}
        </div>
      ),
    },
    {
      title: "Maximum Loan Type",
      className: "text-center",
      value: "max_loan_amt_type",
    },
    {
      title: "Maximum Loan",
      className: "text-center",
      custom: true,
      component: (i) => (
        <div>
          {i.max_loan_amt_val ? _formatNumber(i.max_loan_amt_val) : "-"}
        </div>
      ),
    },
    {
      title: "Role",
      custom: true,
      component: (i) => (
        <div>
          {
            <Badge color={i.role === "Member" ? "primary" : "success"}>
              {i.role}
            </Badge>
          }
        </div>
      ),
    },
    // { title: 'Departmemt', className: 'text-center', value: 'department' },
    {
      title: "Action",
      custom: true,
      component: (item) => (
        <div className="text-center">
          {/* <CustomButton
            size="sm"
            onClick={
              () => history.push(`/admin/member-dashboard?id=${item.id}`)
              // history.push(`/admin/view-member-details?id=${item.id}`)
            }
          >
            <AiFillEye />
            View Details
          </CustomButton> */}
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              style={{
                backgroundColor: theme.primary.main.backgroundColor,
                color: "white",
              }}
            >
              Select Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href="#/action-1"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(true);
                  setChangeText("Make an Admin");
                  setChangeValue(item);
                }}
              >
                Make an Admin
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-2"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(true);
                  setChangeText("Reset Password");
                  setChangeValue(item);
                }}
              >
                Change Password
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-2"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(true);
                  setChangeText("Change Page Access");
                  setChangeValue(item);
                }}
              >
                Page Access
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-3"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(true);
                  setChangeText("Edit Memeber Information");
                  setChangeValue(item);
                }}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-1"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(true);
                  setChangeText("Change Contribution");
                  setChangeValue(item);
                }}
              >
                Change Contribution
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-2"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(true);
                  setChangeText("Change Loan Settings");
                  setChangeValue(item);
                }}
              >
                Change Loan Settings
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-3"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(true);
                  setChangeText("View Contribution Schedule");
                  history.push(
                    getRoleLink(`/membership?user_id=${item.user_id}`)
                  );
                }}
              >
                View Contribution Schedule
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ];

   const handleChange = ({ target: { name, value } }) =>{
 
     setDisabled(false)
      setForm((p) => ({ ...p, [name]: value }));
}



 const infoFields = [
   {
     name: "firstname",
     value: changeValue.firstname,
     required: true,
     type: "text",
     label: "First Name",
     col: 6,
   },
   {
     name: "lastname",
     value: changeValue.lastname,
     required: true,
     type: "text",
     label: "Last Name",
     col: 6,
   },
   {
     name: "phone",
     value: changeValue.phone,
     required: true,
     type: "number",
     label: "Phone number",
     col: 6,
   },
   {
     name: "email",
     value: changeValue.email,
     required: true,
     type: "email",
     label: "Email",
     col: 6,
   },
   {
     name: "address",
     value: changeValue.address,
     type: "textarea",
     label: "Home address",
     col: 6,
   },
   {
     name: "state",
     type:'custom',
     value: changeValue.state,
     custom: true,
     component: (item) => (
       <FormGroup >
         <label>State</label>
         <select
           className="form-control"
           type="select"
           onChange={(e)=> { 
             setDisabled(false);
            setChangeValue((p) => ({
             ...p,
             state: e.target.value,
             society_id: society.id,
           }));}}
           value={changeValue.state || ''}
         >
           <option>{changeValue.state ? `${changeValue.state}`:` ---select--- `}</option>
           {State
             .map((item) => (
               <option
                 labelkey={item}
                 key={item}
                 value={item}
               >
                 {item} 
               </option>
             ))}
         </select>
       </FormGroup>
     ),

     col: 6,
   },
   //  {
   //    name: "password",
   //    value: changeValue.password,
   //    required: true,
   //    type: "password",
   //    label: "Password",
   //    col: 6,
   //  },
 ];
 const pageAccess = [
  {
    name:'dashboard',
    label: 'Dashboard',
    type:"checkbox",

  }
 ]


 const handleSelecgtMember = () => {
  console.log(members)
 }

  const submitLoanChanges = () => {
    setLoading(true);
    updateMembership(
      {
        ...changeValue,
        max_loan_type: changeValue.max_loan_amt_type,
        max_loan_amt: changeValue.max_loan_amt_val,
        _status: "Member updated successfully",
      },
      (res) => {
        setLoading(false);
        setFetch(true);
        if (res.success) {
          toast.success(res.message);
          setIsOpen(false);
          setFetch(false);
        }
      },
      (err) => {
        toast.error(JSON.stringify(err));
        setLoading(false);
      }
    );
  };
    const submitInfoChanges = () => {
      setLoading(true);
      updateUser(
        {
          ...changeValue,
          // max_loan_type: changeValue.max_loan_amt_type,
          // max_loan_amt: changeValue.max_loan_amt_val,
          _status: "Member updated successfully",
        },
        (res) => {
          setLoading(false);
          setFetch(true);
          if (res.success) {
            toast.success(res.message);
            setIsOpen(false);
            setFetch(false);
          }
        },
        (err) => {
          toast.error(JSON.stringify(err));
          setLoading(false);
        }
      );
    };
        const submitPasswordChanges = () => {
          setLoading(true);
          console.log('submitPasswordChanges')
          updatepassword(
            {
             id : changeValue.user_id,
              password:123456,
              _status: "Member updated successfully",
            },
            (res) => {
              setLoading(false);
              setFetch(true);
              if (res.success) {
                toast.success(res.message);
                setIsOpen(false);
                setFetch(false);
              }
            },
            (err) => {
              toast.error(JSON.stringify(err));
              setLoading(false);
            }
          );
        };
  const submitMembershipRoleChanges = () => {
    setLoading(true);
    membershipApiUpdateRole(
      {
        ...changeValue,
        _status: `Member successfully assign to ${changeValue.role}`,
        role: changeValue.role,
      },
      (res) => {
        setLoading(false);
        setFetch(true);
        if (res.success) {
          toast.success(res.message);
          setIsOpen(false);
          setFetch(false);
        }
      },
      (err) => {
        toast.error(JSON.stringify(err));
        setLoading(false);
      }
    );
  };
  const allMembers = useCallback(() => {
    setFetching(true);
    dispatch(
      getMembers(
        society.id,
        () => setFetching(false),
        () => setFetching(false)
      )
    );
  }, [society.id, dispatch]);

  useEffect(() => {
    getDefaultAccount();
    allMembers();
  }, [allMembers, getDefaultAccount, _fetch]);

  // const handleDownload = () => {
  //   // Create a link to the file
  //   const fileUrl = "./onboardFormat.xlsx";

  //   // Create a hidden anchor export NODE_OPTIONS=--openssl-legacy-providerelement
  //   const link = document.createElement("a");
  //   link.href = fileUrl;
  //   link.download = "onboardFormat.xlsx";
  //   link.style.display = "none";

  //   // Append the link to the body and trigger the click event
  //   document.body.appendChild(link);
  //   link.click();
  //   toast.success("Onboarding format download successfully");
  //   // Clean up
  //   document.body.removeChild(link);
  // };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>Modal title</ModalHeader>
        <ModalBody>
          <MemberImportExcel toggle={toggle} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Card body>
        {/* {JSON.stringify(members.filter(item => item.role==="Admin"))} */}
        <CardTitle className="d-flex flex-direction-row justify-content-between">
          <h4 className="mt-1">Members List</h4>
          <p className="pt-2">Total Count: {members.length}</p>
          <div className="d-flex flex-direction-rohandleDownloadw">
            {/* <CustomButton onClick={handleDownload}>
              Download the Onboarding Format
            </CustomButton> */}
            <AdminListInput
              value={selectMembers.user_id}
              onChange={(e) => {
                let value = e.target.value;
                const selected = members.find((e) => e.user_id === value);
                setForm((p) => ({ ...p, user_id: value }));
                setSelectMembers({ ...selected, id: selected.user_id });
              }}
            />
            {/* <a
              download
              href={require("./onboardFormat.xlsx")}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                padding: "10px",
                backgroundColor: "blue",
                borderRadius: "10px",
              }}
            >
              <AiFillEye /> Download template
            </a> */}
            {/* <CustomButton onClick={toggle}>Upload your members</CustomButton> */}
          </div>
        </CardTitle>

        {/* <CustomButton
            className="px-6 mb-3"
            onClick={() => history.push("/dashboard/membership-new")}
          >
            Add a new member
          </CustomButton> */}
        <SearchBar placeholder="Search for a member" />
        {fetching && <Loading size="sm" />}
        <CustomTable
          fields={fields}
          data={members.map((m) => ({
            ...m,
            name: m.firstname + " " + m.lastname,
            role: m.role ? m.role : "",
          }))}
          size="sm"
        />
      </Card>
      <ChangeModal
        cancelModal={cancelModal}
        isOpen={isOpen}
        changeText={changeText}
        changeValue={changeValue}
        onInputChange={onInputChange}
        submitLoanChanges={submitLoanChanges}
        disabled={disabled}
        loading={loading}
        society={society}
        setChangeValue={setChangeValue}
        submitMembershipRoleChanges={submitMembershipRoleChanges}
        submitInfoChanges={submitInfoChanges}
        submitpasswordChanges={submitPasswordChanges}
        infoFields={infoFields}
        pageAccess={pageAccess}
      />
    </div>
  );
}

function LoanSetting() {
  return (
    <div>
      <CustomForm fields={[{ label: "Maximum Loan", name: max_loan }]} />
    </div>
  );
}

const ChangeModal = ({
  isOpen = false,
  changeText = "",
  cancelModal = (f) => f,
  changeValue = {},
  onInputChange = (f) => f,
  submitLoanChanges = (f) => f,
  loading = false,
  disabled = false,
  society = {},
  setChangeValue = (f) => f,
  submitMembershipRoleChanges = (f) => f,
  submitInfoChanges = (f) => f, 
  submitpasswordChanges = (f) => f,
  infoFields = [],
  pageAccess = [],
}) => {
  return (
    <CustomModal
      isOpen={isOpen}
      header={changeText}
      toggle={cancelModal}
      footer={
        changeText === "View Contribution Schedule" ? (
          ""
        ) : changeText === "Make an Admin" ? (
          <div className="text-end">
            <CustomButton
              loading={loading}
              onClick={submitMembershipRoleChanges}
            >
              Assign
            </CustomButton>
          </div>
        ) : changeText === "Edit Memeber Information" ? (
          <>
            <div className="text-end">
              <CustomButton color="danger" onClick={cancelModal}>
                Cancel
              </CustomButton>
              <CustomButton
                disabled={disabled}
                onClick={submitInfoChanges}
                loading={loading}
              >
                Update
              </CustomButton>
            </div>
          </>
        ) : changeText === "Reset Password" ? (
          <div className="text-end">
            <CustomButton color="danger" onClick={cancelModal}>
              Cancel
            </CustomButton>
            <CustomButton
              // disabled={disabled}
              onClick={submitpasswordChanges}
              loading={loading}
            >
              Confirm
            </CustomButton>
          </div>
        ) : (
          <div className="text-end">
            <CustomButton color="danger" onClick={cancelModal}>
              Cancel
            </CustomButton>
            <CustomButton
              disabled={disabled}
              onClick={submitLoanChanges}
              loading={loading}
            >
              Submit
            </CustomButton>
          </div>
        )
      }
    >
      {changeText === "Change Contribution" ? (
        <TextInput
          type="number"
          name="default_contribution"
          label={"Default Contribution"}
          value={changeValue.default_contribution}
          onChange={onInputChange}
        />
      ) : changeText === "View Contribution Schedule" ? (
        <ContributionSchedule />
      ) : changeText === "Make an Admin" ? (
        <div style={{ fontSize: "20px" }}>
          {/* {JSON.stringify(changeValue.role)} */}
          <SelectInput
            value={changeValue.role}
            name="role"
            onChange={({ target: { value, name } }) => {
              setChangeValue((p) => ({
                ...p,
                [name]: value,
                society_id: society.id,
              }));
            }}
            label="Change role"
            options={["Member", "Admin"]}
          />
        </div>
      ) : changeText === "Reset Password" ? (
        <>
          <h4 className="m-1">
            Are you sure you want to reset the Password of the member{" "}
            {changeValue.firstname} to 123456
          </h4>
          <p>
            Please Note that by resetting this password, An email will be sent
            to the Member informing him about his password been reset by you
          </p>
        </>
      ) : changeText === "Edit Memeber Information" ? (
        <>
          <CustomForm fields={infoFields} handleChange={onInputChange} />
        </>
      ) : changeText === "Change Page Access" ? (
        <>
          <CustomForm fields={pageAccess} handleChange={onInputChange} />
        </>
      ) : (
        <Row>
          <Col md={6}>
            <SelectInput
              label="Maximum loan type"
              options={["Automatic", "Manual"]}
              name="max_loan_amt_type"
              value={changeValue.max_loan_amt_type}
              onChange={onInputChange}
            />
          </Col>
          <Col md={6}>
            <TextInput
              type="number"
              name="max_loan_amt_val"
              label={"Maximum Loan Amount"}
              value={changeValue.max_loan_amt_val}
              onChange={onInputChange}
              // placeholder={changeText}
            />
          </Col>
        </Row>
      )}
    </CustomModal>
  );
};
