import { CustomButton } from 'components/UI';
import { CustomTable } from 'components/UI'
import { useHistory } from "react-router";
import DaterangeSelector from 'components/UI/DaterangeSelector'
import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardTitle } from 'reactstrap'
import { theme } from "variables";
import { getRoleLink } from 'views/helper';
import { _fetchApi } from 'redux/actions/api';
import moment from 'moment';

export default function Advert() {
      const goto = useHistory()
    const [form,  setForm] = useState({
        from:"",
        to:"",
        description:"",
        img:"",
        url:"",
    })
    const [data, setData] = useState([])
    const tablefield = [
      {
        title: "Date",
        custom: true,
        component: (item) => (
          <div>{moment(item.created_at).format("YYYY-MM-DD")}</div>
        ),
      },
      { title: "Member Name", value: "member_name" },
      { title: "Reference", value: "ref" },
      { title: "Month", value: "payment_month" },
      {
        title: "Year",
        custom: true,
        component: (item) => <div>{item.payment_year}</div>,
      },
      {
        title: "Status",
        custom: true,
        component: (item) => (
          <div className="text-center">
            <Badge color={item.status === "success" ? "success" : "warning"}>
              {item.status}
            </Badge>
          </div>
        ),
      },
    ];
    useEffect(()=>{
        _fetchApi('/advert?query_type=select',(resp)=>{
            if (resp.success) {
                console.log(resp.data)
                setData(resp.data)
            }
        },(err)=>{console.log(err);})
    },[])
    
    const fields = [
      {
        title: "Date",
        custom: true,
        component: (item) => (
          <div>{moment(item.created_at).format("YYYY-MM-DD")}</div>
        ),
      },
    //   { title: "Name", value: "member_name" },
      // {
      //   title: `Amount Paid (${CURRENCY})`,
      //   custom: true,
      //   component: (item) => (
      //     <div className="text-right">
      //       {formatNumber(
      //         item.credit.toLocaleString("en-US", {
      //           minimumFractionDigits: 2,
      //         })
      //       )}
      //     </div>
      //   ),
      // },
      { title: "Url", value: "url" },
      { title: "Description", value: "description" },
      {
        title: "Date From",
        custom: true,
        component: (item) => <div>{item.date_from}</div>,
      },
      {
        title: "Date To",
        custom: true,
        component: (item) => <div>{item.date_to}</div>,
      },
    ];
  return (
    <div>
      <Card body>
        <div>
          <CustomButton
            onClick={() => goto.push(getRoleLink("/new-advert"))}
          >
            Add
          </CustomButton>
        </div>
        <CardTitle className="h4 text-center mb-4 mt-0">Advert List</CardTitle>
        <DaterangeSelector />

        <CustomTable fields={fields} data={data} />
        {data.length ? null : <EmptyList message="No Advert" />}
      </Card>
    </div>
  );
}
export function EmptyList({ message = "List is empty." }) {
  return (
    <div
      className="text-center rounded pt-3 pb-1"
      style={{ background: theme.primary.main.backgroundColor }}
    >
      <p style={{ color: "white" }}>{message}</p>
    </div>
  );
}
