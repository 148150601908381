import React, {useEffect} from "react";
import { useLocation, Route, Switch,useHistory } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/SideBar";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import routes from "routes/admin";
import sidebarImage from "assets/img/sidebar-4.jpg";
import { useDispatch, useSelector } from "react-redux";
import { init } from "redux/actions/auth";

function Admin() {
  const [image] = React.useState(sidebarImage);
  const [color] = React.useState("");
  const [hasImage] = React.useState(false);
  const location = useLocation();
  const mainPanel = React.useRef(null);
   const authenticated = useSelector((state) => state.auth.authenticated);
   const dispatch = useDispatch();
   const history = useHistory();

  const getRoutes = (routes_objs) => {
    return routes_objs.map(routes => {
      return routes.subItem.map((prop, key) => {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            exact={prop.exact}
            key={key}
          />
        );
      });
    })
  };

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
    useEffect(() => {
      if (!authenticated) {
        dispatch(init(history, (f) => f, location));
      }
    }, [authenticated]);

  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
          <Footer />
        </div>
      </div>
      {/* <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      /> */}
    </>
  );
}
export default Admin;
