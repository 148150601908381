import { CustomButton } from "components/UI";
import { CustomForm } from "components/UI";
import BackButton from "components/UI/BackButton";
import DaterangeSelector from "components/UI/DaterangeSelector";
import React, { useState } from "react";
import toast from "react-hot-toast";
import {useHistory} from "react-router";
import { useSelector } from "react-redux";
import { Card,CardTitle, FormGroup, Label } from "reactstrap";
import { _postApi } from "redux/actions/api";
import { apiURL } from "variables";

export default function Advert() {
 const society = useSelector(state => state.auth.society.id)
 const history = useHistory();
  
  const [form, setForm] = useState({
    description: "",
    image: "",
    url: "",
    society_id:society,
  });

  const handleAttachment = (event) => {
    if (event.target.files.length) {
      let fileObj = event.target.files;
       const fileArray = Array.from(fileObj);

      setForm((p) => ({ ...p, image: fileArray[0],society_id:society }));
    //   setAttachment(Object.values(fileObj));
      const file = event.target.files[0];
      const imageUrl = URL.createObjectURL(file);
      console.log(imageUrl,fileObj);

    //   setImageSrc(imageUrl);
    }
  };
      console.log(form);

  const tablefield = [
    //   {
    //     label: "Type",
    //     type: "custom",
    //     component: () => (
    //       <FormGroup>
    //         <label className="font-weight-bold">Type*</label>
    //         <select
    //           className={`form-control`}
    //           onChange={({ tajrget: { value } }) => {
    //             let type = value.split(",")[0];
    //             let head = value.split(",")[1];
    //             setForm((p) => ({ ...p, type: type, subhead: head }));
    //           }}
    //           // value={form.type}
    //           name="type"
    //         >
    //           <option>--select---</option>
    //           {[
    //             { type: "National Dues", code: 1001 },
    //             { type: "State Dues", code: 1002 },
    //             { type: "Others", code: 1003 },
    //           ].map((item, index) => (
    //             <option key={index} value={[item.type, item.code]}>
    //               {item.type}
    //             </option>
    //           ))}
    //         </select>
    //       </FormGroup>
    //     ),
    //   },

    {
      label: "Url",
      value: form.url,
      required: true,
      name: "url",
      col: 6,
    },
    {
      label: "Description",
      value: form.description,
      name: "description",
      required: true,
      col: 6,
    },
    {
      type: "custom",
      component: () => (
        <FormGroup>
          <label className="font-weight-bold">Type*</label>
          <input type="file" name="description" onChange={handleAttachment}/>
        </FormGroup>
      ),
      label: "Img",
      //   value: form.img,
      //   name: "img",
      //   required: true,
      //   type:"file"
    },

    {
      label: "Date From",
      value: form.date_from,
      type: "date",
      name: "date_from",
      col: 4,
    },
    {
      label: "Date To",
      value: form.date_to,
      type: "date",
      name: "date_to",
      required: true,
      col: 4,
    },
  ];
    const handleChange = ({ target: { name, value } }) => {
      setForm((p) => ({ ...p, [name]: value }));
    };
    

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("description", form.description);
    formData.append("url", form.url);
    formData.append("society_id", form.society_id);
    formData.append("date_from", form.date_from);
    formData.append("date_to", form.date_to);
    formData.append("image", form.image);
    
  fetch(`${apiURL}/advert/new?query_type=create`, {
    method: 'POST',
    body: formData, 
    
  })
  .then(response => response.json())
  .then(data => {
    if(data.success){
         toast.success("Ad created Succesfully")
       history.push(getRoleLink("/adverts")); 
    }else(
        toast.error("failed to create ad")
    )
  } )


    // _postApi(`/advert/new?query_type=create`,formData,(resp) => {console.log(resp),err => console.error(err)})
  }
  return (
    <div>
        <BackButton />
      <Card body>
        <CardTitle className="h4 text-center mb-4 mt-0">Create Advert</CardTitle>

        <CustomForm fields={tablefield} handleChange={handleChange}/>
        <CustomButton
        onClick={handleSubmit}>
            submit
        </CustomButton>
      </Card>
    </div>
  );
}
