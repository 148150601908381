import React, { useState } from "react";
import { Card, CardBody, Row, Col, Form, Spinner } from "reactstrap";
import { _postApi } from "redux/actions/api";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { login } from "redux/actions/auth";
import "assets/css/register.css";
import cover from "../../../images/hands2.jpg";
import "./Login.css";
const Login = () => {
  const dispatch = useDispatch();
  const initForm = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    address: "",
    role: "Admin",
    society_type: "Association",
    society_address: "",
    society_phone: "",
    society_email: "",
    form_type: "Login",
  };
  const [form, setForm] = useState(initForm);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const loginFields = [
    {
      name: "email",
      form: form.email,
      type: "email",
      // label: "Email",
      col: 12,
    },
    {
      name: "password",
      value: form.password,
      type: "password",
      // label: "Password",
      col: 12,
    },
  ];

  const history = useHistory();
  const handleChange = ({ target: { value, name } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
    if (form.name < 5) {
      // alert("hhhh");
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      login(
        form,
        (data) => {
          setLoading(false);
          setForm(initForm);
          console.log(data.societies.length >= 1);
          // alert(JSON.stringify(data.user.role.toLowerCase()))
          if (data && data.societies && data.societies.length) {
            if (data.societies.length > 1) {
              history.push(`/auth/account-switch`);
            } else {
              let role = data.societies[0].role.toLowerCase();
              history.push(`/${role}/dashboard`);
            }
          } else {
            history.push(`/member/dashboard`);
          }
        },
        (e) => {
          setLoading(false);
          setErrorMessage(e.message);
        }
      )
    );
  };

  return (
    <div
      // className={`register ${themeClass}`}
      // // style={theme.primary.main}
      className="login-div"
    >
      <Row className="m-0 login-row">
        <Col
          md={6}
          style={{
            backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ),url(${cover})`,
          }}
          className="img-col"
        >
          <div className="title-wrap">
            <h3 className="bits-head">BITAssos</h3>
            <p>Automated Association system</p>
            <input
              type="submit"
              onClick={() => history.push("/auth/signup")}
              className="goto"
              name=""
              value={`Goto Signup`}
            />
          </div>
        </Col>
        <Col md={6} className="login-col">
          <div className="mobile-head">
            <h3 className="bits-head">BITAssos</h3>
            <p className="bits-slo">Automated Association system</p>
          </div>
          <Row>
            <Col md={1}></Col>
            <Col md={10}>
              <div className="login-form">
                <Form onSubmit={handleLogin}>
                  <Card className="login-card shadow-lg">
                    <CardBody className="">
                      <p className="login-text">Login</p>
                      <hr style={{ padding: 0 }}></hr>
                      <div className="form-group" style={{ paddingTop: 7 }}>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          id="email"
                          value={form.email}
                          name="email"
                          onChange={handleChange}
                        />
                        {/* <label
                          for="email"
                          style={{ color: "rgb(160,160,160)" }}
                          className="form-label"
                        >
                          email
                        </label> */}
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          id="password"
                          name="password"
                          value={form.password}
                          onChange={handleChange}
                        />
                        {/* <label
                          for="password"
                          style={{ color: "rgb(160,160,160)" }}
                          className="form-label"
                        >
                          password
                        </label> */}
                      </div>
                      <Row>
                        <Col md={6}></Col>
                        <Col md={6}>
                          <p
                            style={{
                              fontSize: "12px",
                              marginTop: 3,
                              color: "grey",
                              cursor: "pointer",
                              float: "right",
                            }}
                            onClick={() =>
                              history.push("/auth/forget-password")
                            }
                          >
                            Forget Password?
                          </p>
                        </Col>
                      </Row>
                      {errorMessage && errorMessage !== "" && (
                        <p className="text-danger text-center">
                          {errorMessage}
                        </p>
                      )}
                      <div className="text-center">
                        <button className="auth-btn" type="submit">
                          {loading && <Spinner size="sm" />}
                          Login
                        </button>
                        ` <hr style={{ padding: 0 }}></hr>
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: 5,
                            color: "grey",
                          }}
                        >
                          New cooperative?{" "}
                          <span
                            style={{
                              cursor: "pointer",
                              color: "rgb(96, 14, 230)",
                            }}
                            onClick={() => history.push("/auth/signup")}
                          >
                            Register here!
                          </span>{" "}
                        </p>
                        `
                      </div>
                    </CardBody>
                  </Card>
                </Form>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Login;
