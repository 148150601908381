import React, { useState,useEffect } from "react";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import VerticalMenu from "components/UI/vertical-menu/VerticalMenu";
import { Collapse, Nav } from "reactstrap";
import { themeClass } from "variables";
import { getRoleLink } from "views/helper";
import "./advert.css"
import { Carousel, CarouselItem } from "react-bootstrap";
import { _fetchApi } from "redux/actions/api";
import logo from "../../assets/layered-waves-haikei.png"

export function SideBar({ color, image, routes }) {
  const location = useLocation();
  const history = useHistory();
  const [current, setCurrent] = useState([0]);
  const [adverts, setAdverts] = useState([])
  const isMember = location.pathname.includes("member")
 
    useEffect(() => {
      _fetchApi(
        "/advert?query_type=select",
        (resp) => {
          if (resp.success) {
            setAdverts(resp.data);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }, []);

  const toggle = (cur) => {
    if (current.includes(cur)) {
      setCurrent((p) => [...p.filter((f) => f !== cur)]);
    } else {
      setCurrent((p) => [...p.filter((f) => f !== cur), cur]);
    }
  };

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const RenderSideBar = ({ routes }) => {
    return routes.map((main_route, i) => (
      <VerticalMenu
        key={i}
        title={main_route.title}
        onClick={() => toggle(i)}
        isOpen={current.includes(i)}
        icon={main_route.icon}
      >
        <Collapse isOpen={current.includes(i)}>
          {main_route.subItem.map((prop, key) => {
            if (prop.name)
              return (
                <li className={activeRoute(prop.layout + prop.path)} key={key}>
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    {prop.icon}
                    {/* <i className={prop.icon} />  */}
                    <p
                      style={{
                        marginLeft: 30,
                        fontSize: 12,
                        marginTop: 2,
                      }}
                    >
                      {prop.name}
                    </p>
                  </NavLink>
                </li>
              );
            return null;
          })}
        </Collapse>
      </VerticalMenu>
    ));
  };

  return (
    <div className="sidebar">
      {/* <div className="sidebar" data-image={image} data-color={color}> */}
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div
        className={`sidebar-wrapper flex-direction-column justify-content-between`}
      >
        <div>
          <div className="pt-3 d-flex align-items-center justify-content-center">
            <a
              className=""
              onClick={() => history.push(getRoleLink("/dashboard"))}
              href="#"
            >
              {/* <img src={logo} alt="logo" className="logo-top"  /> */}
              <h3 className="text-light">BIT-Assos</h3>
            </a>
          </div>
          <Nav>
            <RenderSideBar routes={routes} />
          </Nav>
          {/* {isMember && ( */}
            <div className="advert">
              <div className="container">
                <Carousel controls={false} indicators={false} pause="hover">
                  {adverts.map((item, index) => (
                    <CarouselItem key={index}>
                      <a href={item?.url} target="blank">
                        {/* <img src={logo} alt="logo"  /> */}
                        <img src={item?.image} alt={item?.description} />
                      </a>
                      <p className="mb-0">{item?.description}</p>
                    </CarouselItem>
                  ))}
                </Carousel>
                {/* <img src={logo} alt="logo" className="logo" /> */}
              </div>
            </div>
          {/* )} */}
        </div>
        {/* <div>
          <p>Profile</p>
        </div> */}
      </div>
    </div>
  );
}
export default SideBar;
